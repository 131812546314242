@import 'theming/functions';

$border-color: app-color(border-color);
$border-color-10: app-color(border-color, 0.1);
$border-color-light: app-color(border-color-light);
$border-color-dark: app-color(border-color-dark);
$success-color: app-color(success-color);
$success-blue-color: app-color(success-blue-color);
$danger-color: app-color(danger-color);
$warning-color: app-color(warning-color);
$shadow-color: app-color(shadow-color);
$box-shadow: app-palette-val(box-shadow);
$box-shadow-top: app-palette-val(box-shadow-top);

.app-frame-border {
  outline: 2px groove $border-color;
}

.app-shadow {
  -webkit-box-shadow: $box-shadow;
  -moz-box-shadow: $box-shadow;
  box-shadow: $box-shadow;
}

.app-shadow-top {
  box-shadow: $box-shadow-top;
}

.app-border {
  border: 1px solid $border-color;

  &--imp {
    border: 1px solid $border-color !important;
  }
}

.app-border-bold {
  border: 2px solid $border-color;

  &--imp {
    border: 2px solid $border-color !important;
  }
}

.app-border-left {
  border-left: 1px solid $border-color;
}

.app-border-left--imp {
  border-left: 1px solid $border-color !important;
}

.app-border-right {
  border-right: 1px solid $border-color;
}

.app-border-right--imp {
  border-right: 1px solid $border-color !important;
}

.app-border-bottom {
  border-bottom: 1px solid $border-color !important;

  &-10 {
    border-bottom: 1px solid $border-color-10 !important;
  }

  &.--dashed {
    border-bottom-style: dashed !important;
  }
}

.app-border-top {
  border-top: 1px solid $border-color;
}

.app-border-danger {
  border-color: $danger-color;

  &--imp {
    border-color: $danger-color !important;
  }
}

.app-border-warning {
  border-color: $warning-color;

  &--imp {
    border-color: $warning-color !important;
  }
}

.app-border-success {
  border-color: $success-color;

  &--imp {
    border-color: $success-color !important;
  }
}

.app-border-success-blue {
  border-color: $success-blue-color;

  &--imp {
    border-color: $success-blue-color !important;
  }
}

.app-border-light {
  border-color: $border-color-light;

  &--imp {
    border-color: $border-color-light !important;
  }
}

.app-border-dark {
  border-color: $border-color-dark;

  &--imp {
    border-color: $border-color-dark !important;
  }
}

.app-border-radius {
  &-2 {
    border-radius: 2px !important;
  }

  &-4 {
    border-radius: 4px !important;
  }

  &-4-top {
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
  }

  &-4-bottom {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  &-4-right {
    border-top-right-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
  }

  &-4-left {
    border-top-left-radius: 4px !important;
    border-bottom-left-radius: 4px !important;
  }

  &-5 {
    border-radius: 5px !important;
  }

  &-5-top {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
  }

  &-5-bottom {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  &-5-right {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
  }

  &-6 {
    border-radius: 6px !important;
  }

  &-8 {
    border-radius: 8px !important;
  }

  &-8-top {
    border-top-left-radius: 8px !important;
    border-top-right-radius: 8px !important;
  }

  &-8-bottom {
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  &-8-right {
    border-top-right-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
  }

  &-8-left {
    border-top-left-radius: 8px !important;
    border-bottom-left-radius: 8px !important;
  }

  &-10 {
    border-radius: 10px !important;
  }

  &-12 {
    border-radius: 12px !important;
  }

  &-12-top {
    border-top-left-radius: 12px !important;
    border-top-right-radius: 12px !important;
  }

  &-16 {
    border-radius: 16px !important;
  }

  &-100 {
    border-radius: 100px !important;
  }
}
